import React from "react";
import { Link, graphql } from "gatsby";
import Moment from "react-moment";
import "moment/locale/de";
import Markdown from "react-markdown";
import { GatsbyImage } from "gatsby-plugin-image";

import Container from "../components/container";
import Layout from "../components/layout";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.strapiPost;
    //const { previous, next } = this.props.pageContext;
    const { previous, next } = this.props.pageContext;

    return (
      <Layout>
        <Container>
          <div className="px-4 py-6 sm:px-6 lg:px-8 lg:py-12">
            <div className="relative shadow sm:rounded">
              <div className="absolute inset-0">
                <GatsbyImage
                  image={post.image.localFile.childImageSharp.gatsbyImageData}
                  className="h-full w-full object-cover"
                  alt="Rüebenumschlag Mittelland"
                />
                <div
                  className="absolute inset-0"
                  style={{
                    backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.00) 10%, #000000 100%)`,
                  }}
                ></div>
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white">{post.title}</span>
                </h1>
                <p className="mt-6 max-w-lg mx-auto text-center text-xl text-gray-100 sm:max-w-3xl">
                  <Markdown source={post.excerpt} escapeHtml={false} />
                </p>
              </div>
            </div>

            {post.imageCredits && (
              <div className="mt-3 flex text-xs text-gray-400">
                <svg
                  className="flex-none w-4 h-4 text-gray-300"
                  x-description="Heroicon name: solid/camera"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="ml-2">{post.imageCredits}</span>
              </div>
            )}

            <div className="mt-6 prose mx-auto ">
              <div className="ml-0">
                {/*
                <div className="text-sm space-x-1 font-medium text-gray-400">
                  <span className="capitalize">{post.author.name}</span>
                  <span aria-hidden="true">&middot;</span>
                  <span>
                    <time datetime={post.published_at}>
                      <Moment format="DD MMMM YYYY">{post.published_at}</Moment>
                    </time>
                  </span>
                </div> */}

                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center">
                    <span className="px-3 bg-white text-lg font-medium text-gray-600">
                      <span className="capitalize mr-2">
                        {post.author.name}
                      </span>
                      <span aria-hidden="true" className="mr-2">
                        &middot;
                      </span>
                      <time datetime={post.published_at}>
                        <Moment format="DD MMMM YYYY">
                          {post.published_at}
                        </Moment>
                      </time>
                    </span>
                  </div>
                </div>
                <div className="text-sm font-medium text-gray-400 mt-2">
                  {post.categories.map((cat) => (
                    <Link
                      to={`/categories/${cat.slug}`}
                      className="a:hover: underline"
                    >
                      <span className="inline-flex items-center py-0.5 text-sm font-medium text-gray-600 mr-4">
                        <svg
                          className="h-4 w-4 mr-1"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                          />
                        </svg>{" "}
                        {cat.category}
                      </span>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="mt-6 prose text-gray-500 mx-auto">
              <Markdown source={post.body} escapeHtml={false} />
            </div>

            <div className="mt-6 prose text-gray-600 mx-auto">
              <ul>
                {post.beilage.map((bei) => (
                  <li>
                    {bei.bild && <a href={bei.bild.localFile.url}>{bei.titel}</a>}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="pt-12 pl-8 pr-8">
            <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
              <div className="-mt-px w-0 flex-1 flex">
                {previous && (
                  <Link
                    to={`/${previous.slug}`}
                    rel="prev"
                    className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  >
                    <svg
                      className="mr-3 h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span className="hidden md:block">{previous.title}</span>
                  </Link>
                )}
              </div>

              <div className="-mt-px w-0 flex-1 flex justify-end">
                {next && (
                  <Link
                    to={`/${next.slug}`}
                    rel="next"
                    className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  >
                    <span className="hidden md:block">{next.title}</span>
                    <svg
                      className="ml-3 h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </Link>
                )}
              </div>
            </nav>
          </div>
        </Container>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    strapiPost(slug: { eq: $slug }) {
      title
      body
      excerpt
      slug
      imageCredits
      published_at
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      author {
        name
        id
      }
      categories {
        category
        slug
      }
      beilage {
        titel
        beschreibung
        bild {
          localFile {
            publicURL
            url
          }
        }
      }
    }
  }
`;
